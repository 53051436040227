import React from 'react'

import { Layout } from '../components/Layout'
import { Container } from '../components/Container'

const PersonUppgiftsPolicy = () => {
  return (
    <Layout spaceTop={true}>
      <Container>
        <h1 className="font-serif text-3xl text-gray-700 pb-3">Personuppgiftspolicy</h1>
        <hr />
        <section className="pt-8">
          <div className="max-w-3xl">
            <p className="pb-4">
              Advokatfirman Sievo AB är personuppgiftsansvarig för de personuppgifter vi erhåller och behandlar. Vi
              behandlar uppgifterna i enlighet med vad som är erforderligt för att tillvarata klientens intressen inom
              uppdraget samt för att administrera och fullgöra redovisning, hantera klientmedel och utföra fakturering.
              I samband med uppdrag behandlas personuppgifter även i syfte för att utföra jävs- och konfliktkontroller.
            </p>

            <p className="pb-4">
              Vi lämnar inte ut personuppgifter till annan utom för det fall att det följer av lag eller är nödvändigt
              för att tillgodose dina intressen och rättigheter i enlighet med instruktionerna för uppdraget.
              Personuppgifter kan även lämnas ut till domstol eller myndighet samt i förekommande fall till motparter
              eller motpartsombud. Personuppgifter behandlas och sparas därutöver i enlighet med vad följer av Sveriges
              Advokatsamfunds Vägledande Regler för God Advokatsed.
            </p>

            <p className="pb-4">
              Du har rätt att begära ut information om vilka personuppgifter vi behandlar samt rätta eller radera
              felaktiga uppgifter. Du har även rätt att få uppgifterna översända till annan part som du själv anvisar.
              Kontakta oss gärna i det fall du vill ha utförligare information om vår behandling av personuppgifter.
              Ytterligare information om dina rättigheter finns på tillsynsmyndighetens hemsida{' '}
              <a className="text-blue-600 underline" href="https://www.datainspektionen.se/">
                www.datainspektionen.se
              </a>
              .
            </p>
          </div>
        </section>
      </Container>
    </Layout>
  )
}

export default PersonUppgiftsPolicy
